import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout.js'

const Terms = () => {
	return (
		<Layout>
			<div className="Terms">
				<div className="container">
					<article className="content">
						<h1 className="title">Terms of Use</h1>
						<h2 className="subtitle">
							Maytronics Australia Master Terms of Use
						</h2>
						<small>Effective Date: 21 August 2018.</small>
						<h3 id="general-information-regarding-these-terms-of-use">
							1. General Information Regarding These Terms of Use
						</h3>
						<p>
							Master terms: Welcome, and thank you for your interest in
							“Maytronics Australia (“Maytronics Australia,” “MA,” “we,” “our,”
							or “us”). Unless otherwise noted on a particular site or service,
							these master terms of use (“Master Terms”) apply to your use of
							all of the websites that Maytronics Australia Pty Ltd operates.
							These include but are not limited to{' '}
							<a
								href="https://maytronics.com.au/"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://maytronics.com.au
							</a>
							,{' '}
							<a
								href="https://mydolphin.com.au/"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mydolphin.com.au
							</a>
							,{' '}
							<a
								href="https://mydolphin.co.nz"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mydolphin.co.nz
							</a>
							,{' '}
							<a
								href="https://mydolphin.co.za"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mydolphin.co.za
							</a>
							,{' '}
							<a
								href="https://mineralswim.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://mineralswim.com
							</a>
							, together with all other subdomains thereof, (collectively, the
							“Websites”). The Master Terms also apply to all products,
							information, and services provided through the Websites, including
							without limitation; the MyDolphin Mini-Sites,
							Stores/Shops/E-commerce, the Login Services (User Logins, Customer
							Logins), together with the Websites and the Forms therein,
							collectively called “Services”.{' '}
						</p>
						<p>
							Please note that Maytronics Australia does <b>not</b> operate the
							website at{' '}
							<a
								href="https://maytronics.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								https://maytronics.com
							</a>
							.
						</p>
						<p>
							Additional terms: In addition to the Master Terms, your use of any
							Services may also be subject to specific terms applicable to a
							particular Service (“Additional Terms”). If there is any conflict
							between the Additional Terms and the Master Terms, then the
							Additional Terms apply in relation to the relevant Service.
						</p>
						<p>
							Collectively, the Terms: The Master Terms, together with any
							Additional Terms, form a binding legal agreement between you and
							Maytronics Australia in relation to your use of the Services.
							Collectively, this legal agreement is referred to below as the
							“Terms.”
						</p>
						<p>
							<b>
								Human-readable summary of Sec 1: These terms, together with any
								special terms for particular websites, create a contract between
								you and Maytronics Australia. The contract governs your use of
								all websites operated by Maytronics Australia, unless a
								particular website indicates otherwise. These human-readable
								summaries of each section are not part of the contract, but are
								intended to help you understand its terms.
							</b>
						</p>
						<h3 id="your-agreement-to-the-terms">
							2. Your Agreement to the Terms
						</h3>
						<p>
							BY CLICKING “I ACCEPT” OR OTHERWISE ACCESSING OR USING ANY OF THE
							SERVICES (INCLUDING THE LICENSES, PUBLIC DOMAIN TOOLS, AND
							CHOOSERS), YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND
							AGREED TO BE BOUND BY THE TERMS. By clicking “I ACCEPT” or
							otherwise accessing or using any Services you also represent that
							you have the legal authority to accept the Terms on behalf of
							yourself and any party you represent in connection with your use
							of any Services. If you do not agree to the Terms, you are not
							authorized to use any Services. If you are an individual who is
							entering into these Terms on behalf of an entity, you represent
							and warrant that you have the power to bind that entity, and you
							hereby agree on that entity’s behalf to be bound by these Terms,
							with the terms “you,” and “your” applying to you, that entity, and
							other users accessing the Services on behalf of that entity.
						</p>
						<p>
							<b>
								Human-readable summary of Sec 2: Please read these terms and
								only use our sites and services if you agree to them.
							</b>
						</p>
						<h3 id="changes-to-the-terms">3. Changes to the Terms</h3>
						<p>
							From time to time, Maytronics Australia may change, remove, or add
							to the Terms, and reserves the right to do so in its discretion.
							In that case, we will post updated Terms and indicate the date of
							revision. If we feel the modifications are material, we will make
							reasonable efforts to post a prominent notice on the relevant
							Website(s) and notify those of you with a current MA Login Service
							accounts via email. All new and/or revised Terms take effect
							immediately and apply to your use of the Services from that date
							on, except that material changes will take effect 30 days after
							the change is made and identified as material. Your continued use
							of any Services after new and/or revised Terms are effective
							indicates that you have read, understood, and agreed to those
							Terms.
						</p>
						<p>
							<b>
								Human-readable summary of Sec 3: These terms may change. When
								the changes are important, we will put a notice on the website.
								If you continue to use the sites after the changes are made, you
								agree to the changes.
							</b>
						</p>
						<h3 id="no-legal-advice">4. No Legal Advice</h3>
						<p>
							Maytronics Australia is not a law firm, does not provide legal
							advice, and is not a substitute for a law firm. Sending us an
							email or using any of the Services, warranties, licenses or
							otherwise does not constitute legal advice or create an
							attorney-client relationship.
						</p>
						<p>
							<b>
								Human-readable summary of Sec 4: Some of us might be lawyers,
								but we aren’t your lawyer. Please consult your own attorney if
								you need legal advice.
							</b>
						</p>
						<h3 id="content-available-through-the-services">
							5. Content Available through the Services
						</h3>
						<p>
							<em>Provided as-is</em>: You acknowledge that Maytronics Australia
							does not make any representations or warranties about the
							material, data, and information, such as data files, text,
							computer software, code, music, audio files or other sounds,
							photographs, videos, or other images (collectively, the “Content”)
							which you may have access to as part of, or through your use of,
							the Services. Under no circumstances is Maytronics Australia
							liable in any way for any Content, including, but not limited to:
							any infringing Content, any errors or omissions in Content, or for
							any loss or damage of any kind incurred as a result of the use of
							any Content posted, transmitted, linked from, or otherwise
							accessible through or made available via the Services. You
							understand that by using the Services, you may be exposed to
							Content that is offensive, indecent, or objectionable.
						</p>
						<p>
							You agree that you are solely responsible for your reuse of
							Content made available through the Services, including providing
							proper attribution. You should review the terms of the applicable
							license before you use the Content so that you know what you can
							and cannot do.
						</p>
						<p>
							<em>Licensing</em>: MA-Owned Content: The Content (Text, Images
							and Videos) on the Websites is licensed under{' '}
							<a href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
								Attribution-NonCommercial-NoDerivatives 4.0 International
								license,
							</a>{' '}
							unless otherwise marked. See the{' '}
							<a href="https://creativecommons.org/policies/#license">
								Creative Commons Policies page
							</a>{' '}
							for more information. All code including any software produced and
							shared by MA is only to be used and/or copied with permission
							(more info bellow).
						</p>
						<p>
							<em>MA-Owned Code</em>: All of MA’s software code is privately
							owned software; if you have access, please check our code
							repository for the specific license on software you want to reuse.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 5: We try our best to have useful
								information on our sites, but we cannot promise that everything
								is accurate or appropriate for your situation. Content on the
								site is licensed under{' '}
								<a href="http://creativecommons.org/licenses/by-nc-nd/4.0/">
									Attribution-NonCommercial-NoDerivatives 4.0 International
									license,
								</a>{' '}
								unless it says it is available under different terms. If you
								find content through a link on our websites, be sure to check
								the license terms before using it.
							</strong>
						</p>
						<h3 id="content-supplied-by-you">6. Content Supplied by You</h3>
						<p>
							Your responsibility: You represent, warrant, and agree that no
							Content posted or otherwise shared by you on or through any of the
							Services (“Your Content”), violates or infringes upon the rights
							of any third party, including copyright, trademark, privacy,
							publicity, or other personal or proprietary rights, breaches or
							conflicts with any obligation, such as a confidentiality
							obligation, or contains libelous, defamatory, or otherwise
							unlawful material.
						</p>
						<p>
							<em>Licensing Your Content</em>: You retain any copyright that you
							may have in Your Content. You hereby agree that Your Content: (a)
							is hereby licensed under the{' '}
							<a href="https://creativecommons.org/licenses/by/4.0/">
								Creative Commons Attribution 4.0 License
							</a>{' '}
							and may be used under the terms of that license or any later
							version of a{' '}
							<a href="https://creativecommons.org/licenses/by/4.0/">
								Creative Commons Attribution License
							</a>
							, or (b) is in the public domain (such as Content that is not
							copyrightable or Content you make available under CC0), or (c) if
							not owned by you, (i) is available under a{' '}
							<a href="https://creativecommons.org/licenses/by/4.0/">
								Creative Commons Attribution 4.0 License
							</a>{' '}
							or (ii) is a media file that is available under any Creative
							Commons license or that you are authorized by law to post or share
							through any of the Services, such as under the fair use doctrine,
							and that is prominently marked as being subject to third party
							copyright. All of Your Content must be appropriately marked with
							licensing (or other permission status such as fair use) and
							attribution information.
						</p>
						<p>
							<em>Removal</em>: Maytronics Australia may, but is not obligated
							to, review Your Content and may delete or remove Your Content
							(without notice) from any of the Services in its sole discretion.
							Removal of any of Your Content from the Services (by you or
							Creative Commons) does not impact any rights you granted in Your
							Content under the terms of a Creative Commons license.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 6: We do not take any ownership of
								your content when you post it on our sites. If you post content
								you own, you agree it can be used under the terms of{' '}
								<a href="https://creativecommons.org/licenses/by/4.0/">
									CC BY 4.0
								</a>{' '}
								or any future version of that license. If you do not own the
								content, then you should not post it unless it is in the public
								domain or licensed{' '}
								<a href="https://creativecommons.org/licenses/by/4.0/">
									CC BY 4.0
								</a>
								, except that you may also post pictures and videos if you are
								authorized to use them under law (e.g., fair use) or if they are
								available under any CC license. You must note that information
								on the file when you upload it. You are responsible for any
								content you upload to our sites.
							</strong>
						</p>
						<h3 id="prohibited-conduct">7. Prohibited Conduct</h3>
						<p>You agree not to engage in any of the following activities:</p>
						<p>
							<strong>1. Violating laws and rights:</strong>
						</p>
						<ul>
							<li>
								You may not (a) use any Service for any illegal purpose or in
								violation of any local, state, national, or international laws,
								(b) violate or encourage others to violate any right of or
								obligation to a third party, including by infringing,
								misappropriating, or violating intellectual property,
								confidentiality, or privacy rights.
							</li>
						</ul>
						<p>
							<strong>2. Solicitation:</strong>
						</p>
						<ul>
							<li>
								You may not use the Services or any information provided through
								the Services for the transmission of advertising or promotional
								materials, including junk mail, spam, chain letters, pyramid
								schemes, or any other form of unsolicited or unwelcome
								solicitation.
							</li>
						</ul>
						<p>
							<strong>3. Disruption:</strong>
						</p>
						<ul>
							<li>
								You may not use the Services in any manner that could disable,
								overburden, damage, or impair the Services, or interfere with
								any other party’s use and enjoyment of the Services; including
								by (a) uploading or otherwise disseminating any virus, adware,
								spyware, worm or other malicious code, or (b) interfering with
								or disrupting any network, equipment, or server connected to or
								used to provide any of the Services, or violating any
								regulation, policy, or procedure of any network, equipment, or
								server.
							</li>
						</ul>
						<p>
							<strong>4. Harming others:</strong>
						</p>
						<ul>
							<li>
								You may not post or transmit Content on or through the Services
								that is harmful, offensive, obscene, abusive, invasive of
								privacy, defamatory, hateful or otherwise discriminatory, false
								or misleading, or incites an illegal act;
							</li>
							<li>
								You may not intimidate or harass another through the Services;
								and, you may not post or transmit any personally identifiable
								information about persons under 13 years of age on or through
								the Services.
							</li>
						</ul>
						<p>
							<strong>5. Impersonation or unauthorized access:</strong>
						</p>
						<ul>
							<li>
								You may not impersonate another person or entity, or
								misrepresent your affiliation with a person or entity when using
								the Services;
							</li>
							<li>
								You may not use or attempt to use another’s account or personal
								information without authorization; and
							</li>
							<li>
								You may not attempt to gain unauthorized access to the Services,
								or the computer systems or networks connected to the Services,
								through hacking password mining or any other means.
							</li>
						</ul>
						<p>
							<strong>
								Human-readable summary of Sec 8: Play nice. Be yourself. Don’t
								break the law or be disruptive.
							</strong>
						</p>
						<h3 id="disclaimer-of-warranties">8. DISCLAIMER OF WARRANTIES</h3>
						<p>
							TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, MAYTRONICS
							AUSTRALIA OFFERS THE SERVICES (INCLUDING ALL CONTENT AVAILABLE ON
							OR THROUGH THE SERVICES) AS-IS AND MAKES NO REPRESENTATIONS OR
							WARRANTIES OF ANY KIND CONCERNING THE SERVICES, EXPRESS, IMPLIED,
							STATUTORY, OR OTHERWISE, INCLUDING WITHOUT LIMITATION, WARRANTIES
							OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
							NON-INFRINGEMENT. MAYTRONICS AUSTRALIA DOES NOT WARRANT THAT THE
							FUNCTIONS OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE,
							THAT CONTENT MADE AVAILABLE ON OR THROUGH THE SERVICES WILL BE
							ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT ANY SERVERS
							USED BY CC ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
							MAYTRONICS AUSTRALIA DOES NOT WARRANT OR MAKE ANY REPRESENTATION
							REGARDING USE OF THE CONTENT AVAILABLE THROUGH THE SERVICES IN
							TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 8: CC does not make any guarantees
								about the sites, services, or content available on the sites.
							</strong>
						</p>
						<h3 id="limitation-of-liability">9. LIMITATION OF LIABILITY</h3>
						<p>
							TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
							WILL MAYTRONICS AUSTRALIA BE LIABLE TO YOU ON ANY LEGAL THEORY FOR
							ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL,
							SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING WITHOUT
							LIMITATION, LOSS OF REVENUE OR INCOME, LOST PROFITS, PAIN AND
							SUFFERING, EMOTIONAL DISTRESS, COST OF SUBSTITUTE GOODS OR
							SERVICES, OR SIMILAR DAMAGES SUFFERED OR INCURRED BY YOU OR ANY
							THIRD PARTY THAT ARISE IN CONNECTION WITH THE SERVICES (OR THE
							TERMINATION THEREOF FOR ANY REASON), EVEN IF MAYTRONICS AUSTRALIA
							HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
						</p>
						<p>
							TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, MAYTRONICS
							AUSTRALIA IS NOT RESPONSIBLE OR LIABLE WHATSOEVER IN ANY MANNER
							FOR ANY CONTENT POSTED ON OR AVAILABLE THROUGH THE SERVICES
							(INCLUDING CLAIMS OF INFRINGEMENT RELATING TO THAT CONTENT), FOR
							YOUR USE OF THE SERVICES, OR FOR THE CONDUCT OF THIRD PARTIES ON
							OR THROUGH THE SERVICES.
						</p>
						<p>
							Certain jurisdictions do not permit the exclusion of certain
							warranties or limitation of liability for incidental or
							consequential damages, which means that some of the above
							limitations may not apply to you. IN THESE JURISDICTIONS, THE
							FOREGOING EXCLUSIONS AND LIMITATIONS WILL BE ENFORCED TO THE
							GREATEST EXTENT PERMITTED BY APPLICABLE LAW.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 9: MA is not responsible for the
								content on the sites, your use of our services, or for the
								conduct of others on our sites.
							</strong>
						</p>
						<h3 id="indemnification">10. Indemnification</h3>
						<p>
							To the extent authorized by law, you agree to indemnify and hold
							harmless Maytronics Australia, its employees, officers, directors,
							affiliates, and agents from and against any and all claims,
							losses, expenses, damages, and costs, including reasonable
							attorneys’ fees, resulting directly or indirectly from or arising
							out of (a) your violation of the Terms, (b) your use of any of the
							Services, and/or (c) the Content you make available on any of the
							Services.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 10: If something happens because
								you violate these terms, because of your use of the services, or
								because of the content you post on the sites, you agree to repay
								MA for the damage it causes.
							</strong>
						</p>
						<h3 id="privacy-policy">11. Privacy Policy</h3>
						<p>
							Maytronics Australia is committed to responsibly handling the
							information and data we collect through our Services in compliance
							with our <Link to="/privacy-policy">Privacy Policy</Link>, which
							is incorporated by reference into these Master Terms. Please
							review the <Link to="/privacy-policy">Privacy Policy</Link> so you
							are aware of how we collect and use your personal information.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 11: Please read our{' '}
								<Link to="/privacy-policy">Privacy Policy</Link>. It is part of
								these terms, too.
							</strong>
						</p>
						<h3 id="trademark-policy">12. Trademark Policy</h3>
						<p>
							MA’s name and associated brands, logos, icons, and other
							trademarks may only be used with permission. If you need to use
							any of these please email{' '}
							<a href="mailto:infoau@maytronics.com">infoau@maytronics.com</a>{' '}
							or call us <a href="tel:1300 693 657">1300 693 657</a>.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 12: Please ask permission before
								using MA's trademarks.
							</strong>
						</p>
						<h3 id="copyright-complaints">13. Copyright Complaints</h3>
						<p>
							Maytronics Australia respects copyright, and we prohibit users of
							the Services from submitting, uploading, posting, or otherwise
							transmitting any Content on the Services that violates another
							person’s proprietary rights.
						</p>
						<p>
							To report allegedly infringing Content hosted on a website owned
							or controlled by MA, send a Notice of Infringing Materials as set
							out in{' '}
							<a
								href="https://web.archive.org/web/20160612031421/http://www.copyright.gov/legislation/dmca.pdf"
								target="_blank"
								rel="noopener noreferrer"
							>
								The Digital Millennium Copyright Act (“DMCA”)
							</a>{' '}
							and email it to{' '}
							<a href="mailto:infoau@maytronics.com">infoau@maytronics.com</a>.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 13: Please let us know if you find
								infringing content on our websites.
							</strong>
						</p>
						<h3 id="copyright-complaints">13. Copyright Complaints</h3>
						<p>
							Maytronics Australia respects copyright, and we prohibit users of
							the Services from submitting, uploading, posting, or otherwise
							transmitting any Content on the Services that violates another
							person’s proprietary rights.
						</p>
						<p>
							To report allegedly infringing Content hosted on a website owned
							or controlled by MA, send a Notice of Infringing Materials as set
							out in{' '}
							<a
								href="https://web.archive.org/web/20160612031421/http://www.copyright.gov/legislation/dmca.pdf"
								target="_blank"
								rel="noopener noreferrer"
							>
								The Digital Millennium Copyright Act (“DMCA”)
							</a>{' '}
							and email it to{' '}
							<a href="mailto:infoau@maytronics.com">infoau@maytronics.com</a>.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 13: Please let us know if you find
								infringing content on our websites.
							</strong>
						</p>
						<h3 id="termination">14. Termination</h3>
						<p>
							By Maytronics Australia: Maytronics Australia may modify, suspend,
							or terminate the operation of, or access to, all or any portion of
							the Services at any time for any reason. Additionally, your
							individual access to, and use of, the Services may be terminated
							by Maytronics Australia at any time and for any reason.
						</p>
						<p>
							By you: If you wish to terminate this agreement, you may
							immediately stop accessing or using the Services at any time.
						</p>
						<p>
							Automatic upon breach: Your right to access and use the Services
							(including use of your MA Login Service accounts) automatically
							upon your breach of any of the Terms. For the avoidance of doubt,
							termination of the Terms does not require you to remove or delete
							any reference to MA trademarks from your own Content unless
							explicatively requested.
						</p>
						<p>
							Survival: The disclaimer of warranties, the limitation of
							liability, and the jurisdiction and applicable law provisions will
							survive any termination. Unless directed the trademark permissions
							given to Your Content are not impacted by the termination of the
							Terms and shall continue in effect subject to the terms of the
							applicable permissions. Your warranties and indemnification
							obligations will survive for one year after termination.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 14: If you violate these terms,
								you may no longer use our sites.
							</strong>
						</p>
						<h3 id="miscellaneous-terms">15. Miscellaneous Terms</h3>
						<p>
							Choice of law: The Terms are governed by and construed by the laws
							of Australia, including international IP laws. Note that
							Australia’s negotiated trade agreements include IP chapters that
							facilitate trade and investment.
						</p>
						<p>
							Dispute resolution: The parties agree that any disputes between
							Maytronics Australia and you concerning these Terms, and/or any of
							the Services may only brought in a federal or state court of
							competent jurisdiction sitting in the State of Queensland, and you
							hereby consent to the personal jurisdiction and venue of such
							court.
						</p>
						<p>
							No waiver: Either party’s failure to insist on or enforce strict
							performance of any of the Terms will not be construed as a waiver
							of any provision or right.
						</p>
						<p>
							Severability: If any part of the Terms is held to be invalid or
							unenforceable by any law or regulation or final determination of a
							competent court or tribunal, that provision will be deemed
							severable and will not affect the validity and enforceability of
							the remaining provisions.
						</p>
						<p>
							No agency relationship: The parties agree that no joint venture,
							partnership, employment, or agency relationship exists between you
							and Maytronics Australia as a result of the Terms or from your use
							of any of the Services.
						</p>
						<p>
							Integration: These Master Terms and any applicable Additional
							Terms constitute the entire agreement between you and Maytronics
							Australia relating to this subject matter and supersede any and
							all prior communications and/or agreements between you and
							Maytronics Australia relating to access and use of the Services.
						</p>
						<p>
							<strong>
								Human-readable summary of Sec 17: If there is a lawsuit arising
								from these terms, it should be in Queensland and governed by
								Queensland law. We are glad you use our sites, but this
								agreement does not mean we are partners.
							</strong>
						</p>
						<small>Effective Date: 21 August 2018.</small>
					</article>
				</div>
			</div>
		</Layout>
	)
}

export default Terms
